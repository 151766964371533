import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: sessionStorage.getItem('isLogin') || '',
    user: JSON.parse(sessionStorage.getItem('user')) || {}
  },
  mutations: {
    setIsLogin(state, data) {
      sessionStorage.setItem('isLogin', data)
      state.isLogin = data
    },
    setUser(state, data) {
      console.log(data)
      sessionStorage.setItem('user', JSON.stringify(data))
      state.user = data
    }
  },
  actions: {}
})
