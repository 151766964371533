import Vue from 'vue'
import VueI18n from "vue-i18n"
import zh from './zh.json'
import en from './en.json'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('Lang') ? localStorage.getItem('Lang') == 2 ? 'en' : 'zh': 'zh',
  messages: {
    en: {
      ...en,
    },
    zh: {
      ...zh,
    }
  }
})
export default i18n
