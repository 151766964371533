import "babel-polyfill";
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
// main.js
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/basic.css'
import './plugins/element'
import i18n from "./assets/i18n";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
