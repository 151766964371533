import Vue from 'vue'
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Row,
  Col,
  Dialog,
  Form,
  FormItem,
  Radio,
  Breadcrumb,
  BreadcrumbItem,
  Option,
  Select,
  Checkbox,
  Upload,
  Image,
  Table,
  TableColumn,
  Pagination,
  Empty,
  InfiniteScroll,
  Message,
  Loading,
  CollapseItem,
  Collapse
} from 'element-ui'
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Option)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(InfiniteScroll)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

