import Vue from 'vue'
import VueRouter from 'vue-router'
const IndexWrap = () => import( /* webpackChunkName: "indexWrap" */ '../views/IndexWrap.vue')
const Index = () => import( /* webpackChunkName: "index" */ '../views/Index.vue')
const Detail = () => import( /* webpackChunkName: "Detail" */ '../views/Detail.vue')
const Register = () => import( /* webpackChunkName: "Register" */ '../views/Register.vue')
const Success = () => import( /* webpackChunkName: "RegisterS" */ '../views/Success.vue')
const ApplyRight = () => import( /* webpackChunkName: "Apply" */ '../views/ApplyRight.vue')
const Home = () => import( /* webpackChunkName: "Home" */ '../views/Home.vue')
const Search = () => import( /* webpackChunkName: "Search" */ '../views/Search.vue')

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: IndexWrap,
  children: [{
      path: '/',
      name: 'index',
      meta: {
        keepAlive: true
      },
      component: Index
    },
    {
      path: 'detail/:id',
      name: 'detail',
      component: Detail
    },
    {
      path: 'register',
      name: 'register',
      component: Register
    },
    {
      path: 'success',
      name: 'success',
      component: Success
    },
    {
      path: 'apply',
      name: 'apply',
      component: ApplyRight
    },
    {
      path: 'home',
      name: 'home',
      component: Home
    },
    {
      path: 'search/:keyword',
      name: 'search',
      component: Search
    }
  ]
}]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
